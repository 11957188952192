.VariantPropertiesSelector {
  --item-padding: 40px;
}

.VariantPropertiesSelector__Modal {
  width: 600px;
}

.VariantPropertiesSelector__Headline {
  display: flex;
  align-items: center;
  height: 75px;
  border-bottom: 1px solid #d9d9d9;
  color: #363636;
  font-size: 15px;
  font-weight: 700;
  padding-left: var(--item-padding);
  padding-right: var(--item-padding);
}

.VariantPropertiesSelector__Properties {
  overflow-y: auto;
  min-height: 250px;
  max-height: calc(100vh - 400px);
}

.VariantPropertiesSelector__Property {
  display: flex;
  height: 50px;
  padding-left: var(--item-padding);
  padding-right: var(--item-padding);
  border-bottom: 1px solid #d9d9d9;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-out;
}

.VariantPropertiesSelector__Property:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.VariantPropertiesSelector__Property--selected {
  background-color: rgba(0, 0, 0, 0.05);
}

.VariantPropertiesSelector__PropertyCheckbox {
  margin-right: 10px;
}

.VariantPropertiesSelector__PropertyName {
  color: #363636;
  font-size: 15px;
  font-weight: 400;
}

.VariantPropertiesSelector__Loading {
  height: 200px;
}
